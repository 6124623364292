<script>
import Layout from "@/router/layouts/account-cellphone";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      cellphone: {
        status: '',
        number: '',
      },

      code: ''
    };
  },
  validations: {
    code: { required, minLength: minLength(6) },
  },
  methods: {
    checkStatus() {
      api
        .get('user/cellphone/status')
        .then(response => {
          if (response.data.status == 'success') {
            this.cellphone.status = response.data.cellphone.status;
            this.cellphone.number = response.data.cellphone.number;

            if (response.data.cellphone.status == 'pending') {
              this.sendCode();
            } else {
              this.$router.push('/');
            }
          } else {
            this.$router.push('/');
          }
        })
    },
    sendCode() {
      api
        .post('user/cellphone/token', {
          number: this.cellphone.number
        })
        .then(response => {
          if (response.data.status != 'success') {
            this.$toast.error(response.data.message)
          }
        })
    },
    validateCode() {
      this.$v.code.$touch();
      if (this.$v.code.$invalid) {
        return;
      } else {
        if (this.code) {
          api
            .post('user/cellphone/validate', {
              code: this.code,
            })
            .then(response => {
              if (response.data.status == 'success') {
                this.$router.push('/');
                this.$toast.success(response.data.message)
              } else {
                this.$toast.error(response.data.message)
              }
            })
        }
      }
    },
  },
  mounted() {
    this.checkStatus();
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center m-3">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <div class="page-title-box d-md-flex justify-content-between mb-3">
              <div>
                <h3>Digite o código que enviamos por WhatsApp</h3>
                <h5>É um código de 6 dígitos enviado ao seu celular de número {{ cellphone.number }}.</h5>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body table-responsive">
                <b-form class="p-3" @submit.prevent="validateCode">
                  <b-form-group id="code" label="Código" label-for="code">
                    <b-form-input id="code" class="font-size-22 font-weight-bold text-center" style="letter-spacing: 5px" v-model="code" type="number" autocomplete="off"></b-form-input>
                  </b-form-group>
                  <div class="mt-4">
                    <b-button :disabled="!this.code" type="submit" variant="default">Validar</b-button>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>