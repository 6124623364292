<script>
import Footer from "@/components/footer";

export default {
  components: { Footer },
  beforeCreate: function() {
    document.body.className = 'contracts';
  },
  data() {
    return {
    };
  },
  created: () => {
  },
  methods: {
  }
};
</script>

<template>
  <div id="layout-wrapper">
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
      </div>

      <div class="d-flex">
        <img style="height:20px;" src="@/assets/images/logo-header.png" />
      </div>

      <div class="d-flex">
      </div>
    </div>
  </header>
    <div class="main-content ml-0">
      <div class="page-content px-0">
        <div class="container-fluid p-0">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
